import React from 'react'
import ObjectList from 'react-object-list'
import {TextContainsFilter} from 'react-object-list/filters'
// import {FontAwesome} from 'react-object-list/icons'
let apiData = [];


const columns = [
    {dataKey: 'ip', header: 'IP Address', sortKey: 'ip'},
    {dataKey: 'port', header: 'Port', sortKey: 'port'},
    {dataKey: 'is_anonymous', header: 'Anonymous', sortKey: 'is_anonymous'},
    {dataKey: 'is_https', header: 'HTTPS', sortKey: 'is_https'},
    {dataKey: 'country_name', header: 'Country', sortKey: 'country_name'},
    {dataKey: 'speed', header: 'Speed', sortKey: 'speed'},
];

class ProxyList extends React.Component {
  state = {
    currentPage: 1,
    perPage: 25,
    totalCount: apiData.length,
    sortKeys: [],
    data: apiData,
    extraColumns: [],
    filters: [
        {
            Renderer: TextContainsFilter,
            filterKey: 'is_anonymous',
            active: false,
            name: 'Anonymous',
            props: {
            updateDelay: 0,
            },
        },
        {
            Renderer: TextContainsFilter,
            filterKey: 'ip',
            active: false,
            name: 'IP Address',
            props: {
            updateDelay: 0,
            },
        },
        {
            Renderer: TextContainsFilter,
            filterKey: 'port',
            active: false,
            name: 'Port',
            props: {
            updateDelay: 0,
            },
        },
        {
            Renderer: TextContainsFilter,
            filterKey: 'is_https',
            active: false,
            name: 'HTTPS',
            props: {
            updateDelay: 0,
            },
        },
        {
            Renderer: TextContainsFilter,
            filterKey: 'country_name',
            active: false,
            name: 'Country',
            props: {
            updateDelay: 0,
            },
        }
    ],
  }

  constructor(props) {
      super(props)
      // apiData = props["data"]
      //console.log(apiData)
      /*

      this.state["totalCount"] = apiData.length
      this.state["data"] = apiData
      this.state["perPage"] = 10

      this.state = { "perPage": 10 }
      // this.updateData(1)
        */


        let xmlhttp = new XMLHttpRequest();
        let _this = this;
        xmlhttp.onreadystatechange = function() {
          if (xmlhttp.readyState === 4) {
            let response = JSON.parse(xmlhttp.responseText)
            if (xmlhttp.status === 200) {
                apiData = response
                _this.updateData(1)
            }
          }
        };

        if (apiData.length === 0) {
            // let URL = "https://api.proxier.io/proxy?count=300"
            let URL = "js/proxy_data.json?no-cache=" + Math.random() * 10
            xmlhttp.open('GET', URL)
            xmlhttp.setRequestHeader('Content-type', 'application/json')
            xmlhttp.send()
        }


  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.filters !== this.state.filters) {
      this.updateData(1);
    }
  }

  updatePage = currentPage => this.updateData(currentPage)
  updateSorting = sortKey => this.setState(prevState => {
    let sortKeys = [...prevState.sortKeys]
    const currentSort = sortKeys.find(sort => sort.sortKey === sortKey)
    let value = true
    if (currentSort !== undefined && currentSort.value === true) {
      value = false
    }
    sortKeys = [{sortKey: sortKey, value}].concat(sortKeys.filter((k) => k.sortKey !== sortKey))

    const offset = (prevState.currentPage - 1) * prevState.perPage
    return {
      sortKeys,
      data: apiData.sort((a, b) => {
        for (let i = 0; i < sortKeys.length; i++) {
          const order = sortKeys[i].value ? 1 : -1
          if (a[sortKeys[i].sortKey] > b[sortKeys[i].sortKey]) return -1 * order
          if (a[sortKeys[i].sortKey] < b[sortKeys[i].sortKey]) return 1 * order
        }
        return 0
      }).slice(offset, offset + prevState.perPage),
    }
  })
  updateColumns = columnKey => this.setState(prevState => {
    let extraColumns = [...prevState.extraColumns]
    if (extraColumns.includes(columnKey)) {
      extraColumns = extraColumns.filter(key => key !== columnKey)
    } else {
      extraColumns.push(columnKey)
    }
    return {extraColumns}
  })
  addFilter = newFilter => this.setState(prevState => {
    const filters = prevState.filters.map(filter => {
      if (filter.filterKey === newFilter.filterKey) {
        return {...filter, active: true}
      } else {
        return {...filter}
      }
    })
    return {filters}
  })
  removeFilter = filterKey => this.setState(prevState => {
    const filters = prevState.filters.map(filter => {
      if (filter.filterKey === filterKey) {
        return {...filter, active: false, value: ''}
      } else {
        return {...filter}
      }
    })
    return {filters}
  })
  updateFilter = ({filter: filterKey, comparison, value}) => this.setState(prevState => {
    const filters = prevState.filters.map(filter => {
      if (filter.filterKey === filterKey) {
        return {...filter, value, comparison}
      } else {
        return {...filter}
      }
    })
    return {filters}
  })

  updateData = (currentPage) => {
    let data = apiData
    const activeFilters = this.state.filters.filter(filter => filter.active)
    if (activeFilters.length > 0) { // filter data
      data = data.filter(row => {
        for (let i = 0; i < activeFilters.length; i++) {
          const regex = RegExp(activeFilters[i].value, 'i')
          const result = regex.test(row[activeFilters[i].filterKey])
          return result === (activeFilters[i].comparison === 'contains')
        }
        return false
      })
    }
    // paginate data
    const offset = (currentPage - 1) * this.state.perPage
    this.setState(prevState => ({
      totalCount: data.length,
      data: data.slice(offset, offset + prevState.perPage),
      currentPage,
    }))
  }

  render() {
    const { currentPage, perPage, totalCount, sortKeys, extraColumns, data } = this.state
    return <div>
            <div id="proxy-list-table">
            <ObjectList
            columns={columns}
            data={data}
            updateSorting={this.updateSorting}
            filters={this.state.filters}
            meta={{
            currentPage,
            perPage,
            totalCount,
            sortKeys,
            extraColumns,
            }}
            updatePage={this.updatePage}
            maxPages={6}
            updateColumns={this.updateColumns}
            favouritesEnabled={false}
            addFilter={this.addFilter}
            removeFilter={this.removeFilter}
            updateFilter={this.updateFilter}
            // icons={FontAwesome(4)}
            />
            </div>
    </div>
  }
}

export default ProxyList
