import React from 'react';
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"


const ProxierAPI = () => (
  <SwaggerUI url="/swagger.json" />
);

export default ProxierAPI;
