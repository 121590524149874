import React from 'react';
import ReactDOM from 'react-dom';
import ContactForm from './ContactForm';
import ProxyList from './ProxyList';
import ProxierAPI from './ProxierAPI';

// import EmbeddedBrowser from 'react-embedded-browser';

//import TableDemo from './PL';

// import './index.css';
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <ContactForm />
  </React.StrictMode>,
  document.getElementById('contact-app')
);

/*
fetch(`js/proxy_data.json`)
.then(response => response.json())
.then(events => {
*/
    //console.log("index.js events", events);
    // console.log(response);
    ReactDOM.render(
      <React.StrictMode>
        <ProxyList /*data={events}*/ />
      </React.StrictMode>,
      document.getElementById('proxy-list-app')
    );

//});

/*
function show() {
    let eb = document.querySelector('.embedded-browser');
    eb.className = 'embedded-browser anime-slidein';
    eb.open('https://google.com');
}


ReactDOM.render(
    <div>
        <EmbeddedBrowser />
    </div>,
    document.getElementById('browser-app')
);
*/


ReactDOM.render(
  <React.StrictMode>
    <ProxierAPI />
  </React.StrictMode>,
  document.getElementById('proxier-api-app')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
